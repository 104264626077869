import { Cog6ToothIcon } from '@heroicons/vue/24/outline';
import { defineStore, storeToRefs } from 'pinia';
import type { NavItem } from '~~/types/NavItem';
import { useAuthStore } from '../auth';
import type { Workspace } from '~/types/models/workspace';

export const useNavParametresStore = defineStore('nav-parametres', {
  state: () => ({
    items: computed(() => {
      const workspace = storeToRefs(useAuthStore()).workspace as Ref<Workspace>;
      if (!workspace.value) return [];
      return [
        {
          name: 'workspace-parametres',
          redirectToChildren: true,
          label: 'Paramètres',
          icon: Cog6ToothIcon,
          params: { workspace: workspace.value?.slug },
          permission: ['view', 'workspace.parametres'],
          children: [
            {
              name: 'workspace-parametres-marque',
              label: 'Marque',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.marque'],
              children: [],
            },
            {
              name: 'workspace-parametres-utilisateurs',
              label: 'Utilisateurs',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.utilisateurs'],
              children: [],
            },
            {
              name: 'workspace-parametres-vente',
              label: 'Vente',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.vente'],
              children: [],
            },
            {
              name: 'workspace-parametres-financiers',
              redirectToChildren: true,
              label: 'Financiers',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.financiers'],
              children: [
                {
                  name: 'workspace-parametres-financiers-facturation',
                  label: 'Devis et facturation',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.financiers'],
                },
                {
                  name: 'workspace-parametres-financiers-banque',
                  label: 'Banque',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.financiers'],
                },
                {
                  name: 'workspace-parametres-financiers-affectations',
                  label: 'Affectations',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.financiers'],
                },
                {
                  name: 'workspace-parametres-financiers-validations',
                  label: 'Validations',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.financiers'],
                },
              ],
            },
            {
              name: 'workspace-parametres-garants',
              label: 'Garants',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.garants'],
              children: [],
            },
            {
              name: 'workspace-parametres-editions',
              redirectToChildren: true,
              label: 'Éditions PDF',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.editionsPdf'],
              children: [
                {
                  name: 'workspace-parametres-editions-general',
                  label: 'Général',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.editionsPdf.general'],
                },
                {
                  name: 'workspace-parametres-editions-notices',
                  label: 'Notices',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.editionsPdf.notices'],
                },
                {
                  name: 'workspace-parametres-editions-ppcst',
                  label: 'PPCST',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.editionsPdf.ppcst'],
                },
              ],
            },
            {
              name: 'workspace-parametres-documents',
              redirectToChildren: true,
              label: 'Documents',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.documents'],
              children: [
                {
                  name: 'workspace-parametres-documents-types',
                  label: 'Types de documents',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.documents.types'],
                },
              ],
            },
            {
              name: 'workspace-parametres-secteurs',
              label: 'Secteurs',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.secteurs'],
              children: [],
            },
            {
              name: 'workspace-parametres-diffusion',
              redirectToChildren: true,
              label: 'Diffusion',
              params: { workspace: workspace.value?.slug },
              permission: ['view', 'workspace.parametres.diffusion'],
              children: [
                {
                  name: 'workspace-parametres-diffusion-ubiflow',
                  label: 'Ubiflow',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.diffusion.ubiflow'],
                  children: [],
                },
                {
                  name: 'workspace-parametres-diffusion-annonces',
                  label: 'Annonces',
                  params: { workspace: workspace.value?.slug },
                  permission: ['view', 'workspace.parametres.diffusion.annonces'],
                  children: [],
                },
              ],
            },
          ],
        },
      ];
    }) as Ref<NavItem[]>,
  }),
  getters: {},
  actions: {},
});
